import React from "react"

import Header from "../components/header"

const UbuntuIs = () => {
  const ubuntuIs = ["oneness", "compassion and humility",
  "existing in harmony", "spirit of coexistence and reciprocity",
  "respect and dignity for all living beings", "intention in motion",
  "commitment to renewal and restoration", "true", "love", "home"];

  const isInvisible = (e) => {
    const ubuntu = e.target;

    ubuntu.classList.add('isHidden');
    setTimeout(() => {
      ubuntu.classList.remove('clickable');
    }, 1500);

  }

  return (
    <React.Fragment>
      <Header/>
      <main id="ubuntuIs">
        <div id="ubuntu-container">
          {ubuntuIs.map((sentence, i) => {
            return(
              <span key={i}><em className="clickable" onMouseEnter={(e) => isInvisible(e)}>ubuntu</em> is {sentence}. </span>
            )
          })}
        </div>
      </main>
    </React.Fragment>
  )
}

export default UbuntuIs
